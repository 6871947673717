html, body {
  height: 100%; 
  background: #000;
}

#root {
  display: flex;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#myImg {
  position: fixed;
  left: 0;
  top: 0;
  width: 50vw; 
  min-height: 100vh;
  object-fit: none;
  object-position: center center;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50vw; 
  min-height: 100vh;
  object-fit: cover;
}
